import React from "react";

const ProjectMobile = ({ project }) => {
  const { title, description, tags, url, imgClassMobile } = project;
  return (
    <div className="mobile-project">
      <div className="mobile-project__title-wrapper">
        <a href={url} target="_blank" rel="noopener noreferrer">
          <div className="mobile-project__title">{title}</div>
          <img
            src="/images/new-tab-icon.png"
            alt=""
            className="mobile-project__new-tab-icon"
          />
        </a>
      </div>
      <div className="mobile-project__description">{description}</div>
      <div className="mobile-project__tags">{tags}</div>

      {/* <img
        src="/images/github.png"
        alt=""
        className="mobile-project__github-icon"
      /> */}

      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className={`mobile-project__image ${imgClassMobile}`} />
      </a>
    </div>
  );
};

export default ProjectMobile;
