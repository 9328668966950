import React from "react";
import "./AppMobile.scss";
import ProjectMobile from "./ProjectMobile";
import { projects } from "./projects";

const AppMobile = () => {
  const projectList = projects.map((project) => (
    <ProjectMobile key={project.id} project={project} />
  ));

  return (
    <div className="app-mobile">
      <h1 className="mobile-about">
        Hi there! I am Sergej and this is my portfolio.
      </h1>
      <a
        href="https://www.linkedin.com/in/sergej-obrazcov-87685561/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/images/linkedin.png"
          alt=""
          className="mobile-linkedin-logo"
        />
      </a>

      {projectList}
    </div>
  );
};

export default AppMobile;
