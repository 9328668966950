import React, { useEffect, useState } from "react";
import "./App.scss";
import { projects } from "./projects";
import AppMobile from "./AppMobile";

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [carousel, setCarousel] = useState(null);
  const [figure, setFigure] = useState(null);
  const [images, setImages] = useState([]);
  const [theta, setTheta] = useState(0);
  const [currentImage, setCurrentImage] = useState(0);
  const [carouselIsReady, setCarouselIsReady] = useState(false);

  const [project, setProject] = useState(projects[0]);

  useEffect(() => {
    const windowInnerWidth = window.innerWidth;
    setWindowWidth(windowInnerWidth);
    if (windowInnerWidth > 768) {
      setCarousel(document.querySelectorAll(".carousel")[0]);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (carousel !== null) {
      setFigure(carousel.querySelector("figure"));
      setImages(carousel.querySelector("figure").children);
      setTheta(
        (2 * Math.PI) / carousel.querySelector("figure").children.length
      );
    }
  }, [carousel]);

  useEffect(() => {
    if (images?.length > 0) {
      handleWindowResize();

      window.addEventListener("resize", handleWindowResize);

      return () => {
        window.removeEventListener("resize", handleWindowResize);
      };
    }
  });

  useEffect(() => {
    rotateCarousel(currentImage);
  });

  function setupCarousel(imageCount, imageWidth) {
    if (figure) {
      const apothem = imageWidth / (2 * Math.tan(Math.PI / imageCount)) + 60;
      figure.style.transformOrigin = `50% 50% ${-apothem}px`;
      for (let i = 0; i < imageCount; i++) {
        images[i].style.transformOrigin = `50% 50% ${-apothem}px`;
        images[i].style.transform = `rotateY(${i * theta}rad)`;
        images[i].style.backfaceVisibility = "hidden";
      }

      rotateCarousel(currentImage);

      setTimeout(() => {
        setCarouselIsReady(true);
      }, 500);
    }
  }

  const handleWindowResize = () => {
    const windowInnerWidth = window.innerWidth;
    setWindowWidth(windowInnerWidth);
    if (windowInnerWidth > 768) {
      setupCarousel(
        images.length,
        parseFloat(getComputedStyle(images[0]).width)
      );
    }
  };

  function rotateCarousel(imageIndex) {
    if (figure) {
      figure.style.transform = `rotateY(${imageIndex * -theta}rad)`;
    }
  }

  const handleProjectOnClick = (e) => {
    const t = e.target;
    handleProjectData(t.dataset.project);
    if (t.classList.contains("prev")) {
      t.classList.remove("prev");
      const newPrevElement =
        t.previousSibling === null
          ? t.parentElement.lastElementChild.classList
          : t.previousSibling.classList;
      newPrevElement.add("prev");

      const nextElement = figure.querySelector(".next");
      nextElement.classList.remove("next");
      const newNextElement =
        nextElement.previousSibling === null
          ? nextElement.parentElement.lastElementChild.classList
          : nextElement.previousSibling.classList;
      newNextElement.add("next");

      setCurrentImage(currentImage - 1);
    } else if (t.classList.contains("next")) {
      t.classList.remove("next");
      const newNextElement =
        t.nextSibling === null
          ? t.parentElement.firstElementChild.classList
          : t.nextSibling.classList;
      newNextElement.add("next");

      const prevElement = figure.querySelector(".prev");
      prevElement.classList.remove("prev");
      const newPrevElement =
        prevElement.nextSibling === null
          ? prevElement.parentElement.firstElementChild.classList
          : prevElement.nextSibling.classList;
      newPrevElement.add("prev");

      setCurrentImage(currentImage + 1);
    }
  };

  function handleProjectData(id) {
    const projectsById = projects.filter((p) => p.id === id);
    if (projectsById[0]) {
      const project = projectsById[0];
      setProject(project);
    }
  }

  if (windowWidth <= 768) return <AppMobile />;

  return (
    <div className="app">
      <div className="header">
        <span
          className="header__about tooltip"
          data-text="Hi there! I am Sergej and this is my portfolio."
        >
          About
        </span>
        <a
          href="https://www.linkedin.com/in/sergej-obrazcov-87685561/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/images/linkedin.png"
            alt="linkedin"
            className="header__icon icon"
          />
        </a>
      </div>
      <div className="project">
        <div className="project__title-wrapper">
          <a href={project.url} target="_blank" rel="noopener noreferrer">
            <span className="project__title">{project.title}</span>
            <img
              className="project__new-tab-icon icon"
              src="/images/new-tab-icon.png"
              alt=""
            />
          </a>
          {/* <img
            className="project__github-icon push-right icon"
            src="/images/github.png"
            alt=""
          /> */}
        </div>
        <div className="project__description">{project.description}</div>
        <div className="project__tags">{project.tags}</div>
      </div>
      <div className={`carousel ${carouselIsReady ? "visible" : "invisible"}`}>
        <figure>
          <div
            className="project1"
            onClick={handleProjectOnClick}
            data-project="1"
          ></div>
          <div
            className="project2 next"
            onClick={handleProjectOnClick}
            data-project="2"
          ></div>
          <div
            className="project3"
            onClick={handleProjectOnClick}
            data-project="3"
          ></div>
          <div
            className="project1"
            onClick={handleProjectOnClick}
            data-project="1"
          ></div>
          <div
            className="project2"
            onClick={handleProjectOnClick}
            data-project="2"
          ></div>
          <div
            className="project3"
            onClick={handleProjectOnClick}
            data-project="3"
          ></div>
          <div
            className="project1"
            onClick={handleProjectOnClick}
            data-project="1"
          ></div>
          <div
            className="project2"
            onClick={handleProjectOnClick}
            data-project="2"
          ></div>
          <div
            className="project3 prev"
            onClick={handleProjectOnClick}
            data-project="3"
          ></div>
        </figure>
      </div>
    </div>
  );
}

export default App;
