export const projects = [
  {
    id: "1",
    title: "Marvel Comics",
    description:
      "Comic shop example with lists of comics which are fetched from Marvel REST API",
    tags: "#React #Context API #REST API #Flexbox",
    url: "https://master.d2eruwnba2jny9.amplifyapp.com",
    imgClassMobile: "mobile-project__image--project1",
  },
  {
    id: "2",
    title: "GitHub Users",
    description:
      "Web application that uses GutHub API and shows data related to a given user",
    tags: "#React #TypeScript #Redux #GraphQL #Flexbox #Firebase Auth",
    url: "https://master.dxgsoe3h2a8c9.amplifyapp.com/",
    imgClassMobile: "mobile-project__image--project2",
  },
  {
    id: "3",
    title: "Graphic Design",
    description: "Real Graphic Design portfolio with work examples",
    tags: "#React #Router #Flexbox #Sass #Lottie Animations",
    url: "https://www.cloudyvera.com",
    imgClassMobile: "mobile-project__image--project3",
  },
];
